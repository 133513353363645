import React from 'react'
import './catagory.scss'
import Navbar from '../../component/navbar/Navbar'
import Footer from '../../component/fotter/Footer'
import Lottie from "lottie-react";
import ani from "../../image/5aLXCOatGk.json"
import ReactPlayer from 'react-player'
const Catagory = () => {
  return (
        <div className="cat">
            <Navbar/>
            <div className="sectionca">
      <Lottie className='lottie' animationData={ani} />
     
      <div className="p">
      <h3>
        Catagories <span></span>
      </h3>
        </div>
     
    </div>
    <div className="catagory">
    <h1>Conditional issues</h1>
      <h2>Cognative</h2>
            <div id='mental' className="cog">
               
            <div className="video-placeholder2"><ReactPlayer url={"https://www.youtube.com/watch?v=hR5BRi1uz04&list=PLL3y4VLBMQfhCOd9o9CC0QJCycKCMLkWS&index=2"} height={"500px"} width={"470"} loop controls/></div>
          <div className="video-placeholder2"><ReactPlayer url={"https://www.youtube.com/watch?v=NShRLLU0pMM&list=PLL3y4VLBMQfhCOd9o9CC0QJCycKCMLkWS"} height={"500px"} width={"470"}loop controls/></div>
          <div className="video-placeholder2"><ReactPlayer url={"https://www.youtube.com/watch?v=SedGK7GkD3A&list=PLL3y4VLBMQfiybo0o87MPumtLtPf14pLL"} height={"500px"} width={"470"}loop controls/></div>
          <div className="video-placeholder2"><ReactPlayer url={"https://www.youtube.com/watch?v=SedGK7GkD3A&list=PLL3y4VLBMQfiybo0o87MPumtLtPf14pLL"} height={"500px"} width={"470"}loop controls/></div>
          
            </div>
           
            <div id='phy' className="phy">
            <h2>Physical</h2>
            <div className="video-placeholder2"><ReactPlayer url={"https://www.youtube.com/watch?v=SedGK7GkD3A&list=PLL3y4VLBMQfiybo0o87MPumtLtPf14pLL"} height={"500px"} width={"470"}loop controls/></div>
          <div className="video-placeholder2"><ReactPlayer url={"https://www.youtube.com/watch?v=NShRLLU0pMM&list=PLL3y4VLBMQfhCOd9o9CC0QJCycKCMLkWS"} height={"500px"} width={"470"}loop controls/></div>
          <div className="video-placeholder2"><ReactPlayer url={"https://www.youtube.com/watch?v=SedGK7GkD3A&list=PLL3y4VLBMQfiybo0o87MPumtLtPf14pLL"} height={"500px"} width={"470"}loop controls/></div>
          <div className="video-placeholder2"><ReactPlayer url={"https://www.youtube.com/watch?v=hR5BRi1uz04&list=PLL3y4VLBMQfhCOd9o9CC0QJCycKCMLkWS&index=2"} height={"500px"} width={"470"}loop controls/></div>
          
            </div>
            <div id='sir' className="sir">
            <h2>Spirtual</h2>
            <div className="video-placeholder2"><ReactPlayer url={"https://www.youtube.com/watch?v=SedGK7GkD3A&list=PLL3y4VLBMQfiybo0o87MPumtLtPf14pLL"} height={"500px"} width={"470"}loop controls/></div>
          <div className="video-placeholder2"><ReactPlayer url={"https://www.youtube.com/watch?v=NShRLLU0pMM&list=PLL3y4VLBMQfhCOd9o9CC0QJCycKCMLkWS"} height={"500px"} width={"470"}loop controls/></div>
          <div className="video-placeholder2"><ReactPlayer url={"https://www.youtube.com/watch?v=SedGK7GkD3A&list=PLL3y4VLBMQfiybo0o87MPumtLtPf14pLL"} height={"500px"} width={"470"}loop controls/></div>
          <div className="video-placeholder2"><ReactPlayer url={"https://www.youtube.com/watch?v=hR5BRi1uz04&list=PLL3y4VLBMQfhCOd9o9CC0QJCycKCMLkWS&index=2"} height={"500px"} width={"470"}loop controls/></div>
          
            </div>
      </div>
            <Footer/>
        </div>
  )
}

export default Catagory
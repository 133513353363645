import React, { useState, useEffect } from 'react';
import Navbar from '../../component/navbar/Navbar';
import Footer2 from '../../pages/detail/fotter/Footer2';
import ReactPlayer from 'react-player';
import firebase, { firestore } from '../../firebaseConfige'; // Import firebase and firestore
import './detail.scss';

const DetailDoc = () => {
  const [doctors, setDoctors] = useState([]); // Added the missing state variable for doctors
  const [videos, setVideos] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const doctorsRef = firestore.collection('admin-user');
        const snapshot = await doctorsRef.get();
        const doctorsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDoctors(doctorsData);
      } catch (error) {
        console.error('Error fetching doctors:', error);
      }
    };

    fetchDoctors();
  }, []);

  useEffect(() => {
    if (selectedDoctor) {
      const fetchVideos = async () => {
        try {
          const userVideosRef = firestore.collection('videos').doc(selectedDoctor.id).collection('user-videos');
          const snapshot = await userVideosRef.get();
          const videosData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setVideos(videosData);
        } catch (error) {
          console.error('Error fetching videos:', error);
        }
      };

      fetchVideos();
    }
  }, [selectedDoctor]);

  const handleDetailDoc = (doctor) => {
    setSelectedDoctor(doctor);
    // Handle navigation to the detail page for the selected doctor
    console.log("Clicked on doctor:", doctor);
  };

  return (
    <div className="norm">
      <Navbar />
      <div className="titlen">
        <h1>Our doctors</h1> {/* Moved the h1 here */}
      </div>
      <div className="docs2">
        {doctors.map(doctor => (
          <div key={doctor.id} className="doctor" onClick={() => handleDetailDoc(doctor)}>
           
           {doctor.profileImage && <img src={doctor.profileImage} alt={doctor.name} />} {/* Display profile image */}
            {/* Display additional details */}
            <h2>{doctor.name}</h2>
            <p>Category: {doctor.category}</p> {/* Changed specialization to category */}
            {/* Add more details as needed */}
          </div>
        ))}
      </div>
      {/* Render videos associated with the selected doctor */}
      {selectedDoctor && (
        <div className="videos-section">
          <h2>Videos uploaded by <span>{selectedDoctor.name}</span></h2>
          <div className="videos">
            {videos.map(video => (
              <div key={video.id} className="video">
                <ReactPlayer url={video.videoURL} controls />
                <h3>Title: {video.title}</h3>
                <p>Description: {video.description}</p>
                
              </div>
            ))}
          </div>
        </div>
      )}
      <Footer2 />
    </div>
  );
};

export default DetailDoc;

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from '../src/pages/home/Home';
import Contact from '../src/pages/contact/Contact'
import About from '../src/pages/about/About'
import Doctor from '../src/pages/doctor/Doctor'
import Login from '../src/pages/login/Login'
import DetailDoc from '../src/pages/detail/DetailDoc'
import Catagory from './pages/catagory/Catagory';
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Login />} />  {/* Route for Home */}
        <Route path="/Home" element={<Home />} />  {/* Route for Home */}
        <Route path="/Contact" element={<Contact />} />  {/* Route for Cause */}
        <Route path="/About" element={<About />} />  {/* Route for Cause */}
        <Route path="/Doctor" element={<Doctor />} />  {/* Route for Cause */}
        <Route path="/DetailDoc" element={<DetailDoc />} />  {/* Route for Cause */}
        <Route path="/Catagory" element={<Catagory />} />  {/* Route for Cause */}
        <Route path="/Catagory/:mental" element={<Catagory />} />  {/* Route for Cause */}
        <Route path="/Catagory/:phy" element={<Catagory />} />  {/* Route for Cause */}
        <Route path="/Catagory/:sir" element={<Catagory />} />  {/* Route for Cause */}
        {/* Add other routes here */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;

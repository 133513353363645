import React from 'react'
import Navbar from '../../component/navbar/Navbar'
import Footer1 from '../../pages/doctor/fotter/Footer1'
import List from '../../pages/doctor/list/List'
import './doctor.scss'
export default function Doctor() {
  return (
    <div className="doc">
        <Navbar/>
        <div className="title">
        <h2>our specalized doctors</h2>
        </div>
         <div className="mel1">
          <h3>Mental Health</h3>
          <p>often referred to as a psychiatrist or psychologist depending on their specific training and qualifications, is a medical professional specializing in diagnosing, treating, and preventing mental health disorders and conditions. These professionals play a crucial role in helping individuals cope with various psychological issues and improve their overall well-being.
          </p>
          <div className="docs2">
          <div className="d1">
            <h2>Dr. GG</h2>
          </div>
          <div className="d2">
            <h2 >Dr. GG</h2>
          </div>
          <div className="d3">
            <h2>Dr. GG</h2>
          </div>
          <div className="d4">
            <h2>Dr. GG</h2>
          </div>
        </div>
        </div>
       <div className="mel2">
          <h3>Physical Health</h3>
          <p>also known as a physician or medical doctor (MD), specializes in diagnosing, treating, and preventing illnesses and injuries related to the body's physical health. These healthcare professionals are trained to address a wide range of medical conditions, from acute illnesses and injuries to chronic diseases and preventive care.
          </p>
          <div className="docs2">
          <div className="d1">
            <h2>Dr. GG</h2>
          </div>
          <div className="d2">
            <h2 >Dr. GG</h2>
          </div>
          <div className="d3">
            <h2>Dr. GG</h2>
          </div>
          <div className="d4">
            <h2>Dr. GG</h2>
          </div>
        </div>
        </div>
        <div className="mel3">
          <h3>Spirtual Health</h3>
          <p> often referred to as a spiritual counselor, spiritual healer, or spiritual guide, specializes in providing support, guidance, and healing for individuals seeking to explore and enhance their spiritual well-being. While not medical doctors in the traditional sense, spiritual health practitioners focus on the holistic aspects of health, including the spiritual dimension, and aim to help individuals achieve balance and harmony in their lives.
          </p>
          <div className="docs2">
          <div className="d1">
            <h2>Dr. GG</h2>
          </div>
          <div className="d2">
            <h2 >Dr. GG</h2>
          </div>
          <div className="d3">
            <h2>Dr. GG</h2>
          </div>
          <div className="d4">
            <h2>Dr. GG</h2>
          </div>
        </div>
        </div>
        <Footer1/>
    </div>
  )
}

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar8 from '../../component/navbar/Navbar';
import Footer from '../../component/fotter/Footer';
import "./home.scss";
import ParticlesBackground from '../../component/config/ParticlesBackground';
import Lottie from "lottie-react";
import ani from "../../image/bxElzzBUtx.json";
import doc from "../../image/IqSDnachVv.json";
import loti from "../../image/TWENGVgyUU.json";
import phy from "../../image/YPCE8OcaiC.json";
import spi from "../../image/lYLU4KU5t5.json";
import ReactPlayer from 'react-player'
import firebase from '../../firebaseConfige';
import 'firebase/compat/firestore';
import { firestore } from '../../firebaseConfige'; // Import firebase and firestore



const Home = () => {
  const navigate  = useNavigate();
  const [doctors, setDoctors] = useState([]);

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const doctorsRef = firestore.collection('admin-user');
        const snapshot = await doctorsRef.get();
        const doctorsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDoctors(doctorsData);
      } catch (error) {
        console.error('Error fetching doctors:', error);
      }
    };

    fetchDoctors();
  }, []);


  const handleReadMore = () => {
    navigate("/About");
  };

  const handleViewMore = () => {
    navigate("/Doctor");
  };
  const handleDetailDoc = () => {
    navigate("/DetailDoc");
  };
  const handleCategory = () => {
    const categoryId = "mental"; // Replace "physical" with the actual ID of the category
    navigate(`/catagory/${categoryId}`); // Use the navigate function to navigate to the specified category page
 };
 const handleCategoryp = () => {
  const categoryId = "phy"; // Replace "physical" with the actual ID of the category
  navigate(`/catagory/${categoryId}`); // Use the navigate function to navigate to the specified category page
};
const handleCategorys = () => {
  const categoryId = "sir"; // Replace "physical" with the actual ID of the category
  navigate(`/catagory/${categoryId}`); // Use the navigate function to navigate to the specified category page
};

  return (
    <div className="home">
      <Navbar8 />
      <div className="section2">
        <ParticlesBackground />
        <Lottie className='lottie' animationData={ani} />
        <div className="p">
          <h3>
            Welcome to <span>Doctor online</span>
          </h3>
          <p>
          Established in [2024], Mindpluse Solution is a pioneering force in the realm of advanced digitalization technology. With a visionary approach, the company has been at the forefront of revolutionizing traditional business practices through cutting-edge digital solutions.

          </p> 
        </div>
      </div>
      
      <div className="mid-section">
        <Lottie className='lottie' animationData={doc} />
        <div className="p">
          <h3>
            About us
          </h3>
          <p>
          Established in [2024], Mindpluse Technology is a pioneering force in the realm of advanced digitalization technology. With a visionary approach, the company has been at the forefront of revolutionizing traditional business practices through cutting-edge digital solutions.
          <br />
          </p> 
          <button onClick={handleReadMore}>Read more</button>
        </div>
      </div>
      
      <div className="sec_mid">
        <div className="title">
          <h2>Our expertise</h2>
        </div>
        <div className="experts">
          <div onClick={handleCategory} className="mental">
            <Lottie className='lottie' animationData={loti} />
            <h2>Cognitive health</h2>
          </div>
          <div onClick={handleCategoryp} className="physical">
            <Lottie className='lottie' animationData={phy} />
            <h2>Physical well-being</h2>
          </div>
          <div onClick={handleCategorys} className="spirit">
            <Lottie className='lottie' animationData={spi} />
            <h2>Spiritual well-being</h2>
          </div>
        </div>
        
        <div className="bon1">
         <a href=""><button onClick={handleCategory}>Read more</button> </a> 
        </div>
        
          <div className="docvids">
            <div className="v1">
            <div ><ReactPlayer url={"https://www.youtube.com/watch?v=2Q2BEzJJEL8"} height={"300px"} width={"400px"} loop controls/></div>
            </div>
            <div className="v2">
            <div ><ReactPlayer url={"https://www.youtube.com/watch?v=NRHCHVrq8nY"} height={"300px"} width={"400px"}loop controls/></div>

            </div>
            <div className="v3">
            <div ><ReactPlayer url={"https://www.youtube.com/watch?v=Lj7gM6eIDu0"} height={"300px"} width={"400px"}loop controls/></div>

            </div> 
          </div>



        <h1>Our doctors</h1>
        <div className="docs1">
      {doctors.map(doctor => (
        <div key={doctor.id} className="doctor" onClick={() => handleDetailDoc(doctor.id)}>
          {doctor.profileImage && <img src={doctor.profileImage} alt={doctor.name} width={"300px"} />} 
          <h2>{doctor.name}</h2> {/* Display profile image */}
        </div>
      ))}
    </div>

        <div className="btn2">
        <a href=""><button onClick={handleViewMore}>Read more</button> </a> 
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;

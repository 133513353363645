import React, { Component } from 'react'
import './footer2.scss'
export class Footer2 extends Component {
  render() {
    return (
        <div class="footer1">
        <div class="container">
            <div class="row">
                <div class="footer-col">
                    <img src="./image/logo-removebg-preview.png" alt="" />
                </div>
                <div class="footer-col">
                    <h4>Quick links</h4>
                    <ul>
                        <li>Contact us</li>
                        <li>About us</li>
                        <li>Help</li>
                        
                    </ul>
                </div>
                <div class="footer-col">
                    <h4>call us.</h4>
                    <ul>
                        <li><a href="">+251234567</a></li>
                        <li><a href="">+251246822</a></li>
                        <li><a href="">+251359765</a></li>
                        <li><a href=""></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    )
  }
}

export default Footer2